.bubble {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 50%;
  background-color: #bab7aa;
  border: 1px solid white;
  color: #0c090c;
  overflow: visible;
}

.bubble_owner {
  background-color: #C5E963;
  border: 2px solid white;
}

.bubble span {
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  display: block;
  overflow: hidden;
}

/* Colors temporary disabled for top performers*/

/* .bubble_first {
  background-color: #e7c6b4;
  border: 4px solid white;
} */

/* .bubble_second {
  background-color: #c1deba;
  border: 4px solid white;
} */

/* .bubble_third {
  background-color: #eaebe5;
  border: 4px solid white;
} */

.bubble_owner_mark {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: calc(50% - 8px);
  left: calc(50%- 8px);
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: #C5E963;
  color: #C5E963;
  border: 2px solid white;
  z-index: 100;
}

/* .bubble_owner_mark::before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 0px;
  left: calc(50% - 3px);
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: #c5e961;
  border: 2px solid white;
} */

.name {
  font-size: 14px;
}

.largeText {
  font-weight: bold;
  font-size: 16px;
}

.midText {
  font-weight: bold;
  font-size: 12px;
}

.smallText {
  font-size: 10px;
  font-weight: bold;
}