@font-face {
    font-family: 'Gilroy';
    src: url('../../assets/fonts/Gilroy/Gilroy-Medium.eot');
    src: local('Gilroy Regular'), local('Gilroy-Regular'),
        url('../../assets/fonts/Gilroy/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Gilroy/Gilroy-Regular.woff2') format('woff2'),
        url('../../assets/fonts/Gilroy/Gilroy-Regular.woff') format('woff'),
        url('../../assets/fonts/Gilroy/Gilroy-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../assets/fonts/Gilroy/Gilroy-Medium.eot');
    src: local('Gilroy Medium'), local('Gilroy-Medium'),
        url('../../assets/fonts/Gilroy/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Gilroy/Gilroy-Medium.woff2') format('woff2'),
        url('../../assets/fonts/Gilroy/Gilroy-Medium.woff') format('woff'),
        url('../../assets/fonts/Gilroy/Gilroy-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../assets/fonts/Gilroy/Gilroy-Semibold.eot');
    src: local('Gilroy Semibold'), local('Gilroy-Semibold'),
        url('../../assets/fonts/Gilroy/Gilroy-Semibold.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Gilroy/Gilroy-Semibold.woff2') format('woff2'),
        url('../../assets/fonts/Gilroy/Gilroy-Semibold.woff') format('woff'),
        url('../../assets/fonts/Gilroy/Gilroy-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Aldrich';
    src: url('../../assets/fonts/Aldrich/Aldrich-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}