* {
  box-sizing: border-box;
}

#root {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}

html,
body {
  width: 100%;
  height: 100%;
  transition: background 0.3s ease-in;
}